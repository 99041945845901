<template>
  <div class="logobind">
    <div class="title">修改登录密码</div>
    <div class="pipe">绑定第三方应用账号：微信、支付宝</div>
    <div class="pipe">绑定微信或支付宝成功后，可通过微信或支付宝进行快捷登录，初次通过快捷方式登录将默认您的孔雀有礼账户已绑定该应用
    </div>
    <div class="pay-wrap">
        <div class="payleft">
          <img src="../../../assets/icon/zhifubao.png" alt="" srcset="">
        </div>
        <div class="payright">
          <div class="state">
            <span>支付宝</span>
            <span class="last">未绑定</span>
          </div>
          <el-button type="primary">主要按钮</el-button>
        </div>
    </div>
    <div class="pay-wrap">
      <div class="payleft">
          <img src="../../../assets/icon/weixin.png" alt="" srcset="">
        </div>
        <div class="payright">
          <div class="state">
            <span>微信</span>
            <span class="last">未绑定</span>
          </div>
          <el-button type="success" plain>解除绑定</el-button>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'logobind',
  components: {

  }
}
</script>

<style lang='scss' scoped>
  @import "../../../assets/scss/common.scss";
  @import "../../../assets/scss/reset.scss";
  .logobind{
    padding: 30px 20px;
    .title{
         font-size: 18px;
         color: #333333;
         padding-bottom: 25px;
     }
    .pipe{
      font-size: 14px;
      color: #666666;
      margin-bottom: 6px;
    }
    .pay-wrap{
      overflow: hidden;
      margin: 80px 0 0 50px;
      .payleft{
        float: left;
        margin-right: 30px;
      }
      .payright{
        float: left;
        .state{
          font-size: 18px;
          color: #333333;
          margin-bottom: 15px;
          .last{
            font-size: 14px;
            color: #999999;
            margin-left: 10px;
          }
        }
      }
    }
  }
</style>
